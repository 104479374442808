const APP_ROUTES = {
  HOME: '/',
  AUTH_HOME: '/discover',
  get SELECT_AREAS() {
    return `${this.AUTH_HOME}/select-areas`
  },
  get SELECT_SPHERES() {
    return `${this.AUTH_HOME}/select-spheres`
  },
  get REVIEW_CONTEXT() {
    return `${this.AUTH_HOME}/review-context`
  },
  get PERSONAS() {
    return `${this.AUTH_HOME}/personas`
  },
  get QUESTIONS() {
    return `${this.AUTH_HOME}/questions`
  },
  get SUMMARY() {
    return `${this.AUTH_HOME}/summary`
  },
  get SCENARIO() {
    return `${this.AUTH_HOME}/scenario`
  },
  AUTH_SIGN_IN: '/auth/signin',
  AUTH_REGISTER: '/auth/register',
  AUTO_SIGN_OUT: '/auth/signout',
}

const LOCAL_STORAGE_KEY = {
  SELECTED_AREAS: 'DELPHI_SELECTED_AREAS',
  SELECTED_SPHERES: 'DELPHI_SELECTED_SPHERES',
  RAW_CONTEXT: 'DELPHI_RAW_CONTEXT',
  PERSONAS: 'DELPHI_PERSONAS',
  QUESTIONS: 'DELPHI_QUESTIONS',
  SUMMARY: 'DELPHI_SUMMARY',
  SCENARIO: 'DELPHI_SCENARIO',
  SOURCES: 'DELPHI_SOURCES',
  SCENARIO_ID: 'DELPHI_SCENARIO_ID',
  CARDS: 'DELPHI_CARDS',
  DEBUG: 'DELPHI_DEBUG_MODE',
}

const API_ROUTES = {
  BASE_URL: process.env.NEXT_PUBLIC_API_URL,
  get CSRF() {
    return `${this.BASE_URL}/sanctum/csrf-cookie`
  },
  get LOGIN() {
    return `${this.BASE_URL}/api/login`
  },
  get SAVE_SCENARIO() {
    return `${this.BASE_URL}/api/scenario`
  },
  get GET_SCENARIO() {
    return `${this.BASE_URL}/api/scenario/{uuid}`
  },
  INTERNAL_AUTH_REGISTER: '/api/auth/register',
}

export { APP_ROUTES, LOCAL_STORAGE_KEY, API_ROUTES }
