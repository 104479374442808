'use client'

import { useContext } from 'react'
import { Button, Link } from '@chakra-ui/react'
import { usePathname } from 'next/navigation'

import { APP_ROUTES } from '@/lib/constants'
import { GeneralContext } from '@/contexts/General'

const styles = {
  logo: {
    style: {
      backdropFilter: 'blur(5px)',
      backgroundColor: 'transparent',
      padding: '16px 10px',
      fontSize: '1.5rem',
      borderRadius: '.5rem',
    },
  },
}

const Logo = ({
  logoText = 'Delphi',
  session = null
}) => {
  const { onOpen } = useContext(GeneralContext)
  const pathname = usePathname()

  const props = {
    style: { ...styles.logo.style },
    fontFamily: 'heading'
  }

  const onClick = () => {
    if (pathname === '/') return
    onOpen()
  }

  if (!session) {
    return (
      <Link
        {...props}
        href={APP_ROUTES.HOME}>
        {logoText}
      </Link>
    )
  }

  return (
    <Button
      {...props}
      onClick={onClick}
    >
      {logoText}
    </Button>
  )
}

export default Logo
