import { Link } from '@chakra-ui/next-js'
import { Button } from '@chakra-ui/react'

const styles = {
  defaultHover: {
    _hover: {
      transform: 'scale(1.05)',
      transition: 'transform 0.3s ease',
      fontWeight: '500',
    },
  },
  commonCtaStyle: {
    borderRadius: '.5rem',
    padding: '1rem 1.5rem',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: '500',
    height: 'auto',
    transition: 'all 0.3s ease',
    backdropFilter: 'blur(5px)',
  },
  primary: {
    border: '2px solid #CFFFDA',
    backgroundColor: '#88FCA2',
  },
  secondary: {
    border: '1px solid #D4DBD4',
    bg: '#F0F5F0',
    color: '#1f1f1fb3',
    borderRadius: '6px',
    padding: '0.75rem 1.5rem',
  },
  outlined: {
    display: 'inline-block',
    backgroundColor: 'transparent',
    border: '1px solid #000',
    color: '1px solid #000',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: '0.5',
  },
}

const removeUselessProps = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { primary, secondary, isDisabled, outlined, type, ...rest } = props
  return rest
}

const CtaManager = (props) => {
  const {
    href,
    primary = false,
    secondary = false,
    isDisabled = false,
    outlined = false,
    type = 'button',
  } = props

  const injectPrimaryStyles = primary ? styles.primary : {}
  const injectSecondaryStyles = secondary ? styles.secondary : {}
  const injectDisabledStyles = isDisabled ? styles.disabled : {}
  const injectOutlinedStyles = outlined ? styles.outlined : {}

  const injectStyles = {
    ...styles.commonCtaStyle,
    ...injectPrimaryStyles,
    ...injectSecondaryStyles,
    ...injectDisabledStyles,
    ...injectOutlinedStyles,
    ...styles.defaultHover,
    ...props,
  }

  let ControllerComponent = null

  if (isDisabled) {
    return <Button {...injectStyles} />
  }

  const normalizedStylesAndProps = removeUselessProps(injectStyles)

  switch (!!href) {
    case true:
      ControllerComponent = <Link {...normalizedStylesAndProps} />
      break
    default:
      ControllerComponent = <Button {...normalizedStylesAndProps} type={type} />
  }

  return ControllerComponent
}

export default CtaManager
