'use client'
import { signOut } from 'next-auth/react'
import { HStack } from '@chakra-ui/react'

import { APP_ROUTES } from '@/lib/constants'

import Cta from '@/components/molecules/Cta'

export default () => (
  <Cta
    outlined
    onClick={() => signOut({ callbackUrl: APP_ROUTES.HOME })}
    padding="12px 18px"
  >
    <HStack>
      <span>Sign out</span>
    </HStack>
  </Cta>
)
