'use client'

import { CheckCircleIcon } from '@chakra-ui/icons'
import { Center, Flex, Heading, ScaleFade, Text } from '@chakra-ui/react'

import { APP_ROUTES } from '@/lib/constants'
import { useLocalStorageKeys } from '@/hooks/useLocalStorageState'

import Content from '@/components/atoms/Content'

import Cta from '@/components/molecules/Cta'
import LabeledIcon from '@/components/molecules/LabeledIcon'

const styles = {
  mainWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'auto',
    minHeight: '100dvh',
    padding: {
      base: '60px 2rem',
    },
  },
  headWrapper: {
    flexDirection: 'column',
    gap: '1.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emoji: {
    fontWeight: 'bold',
    fontSize: {
      base: '45px',
      md: '90px',
    },
  },
  heading: {
    maxW: '650px',
    as: 'h1',
    fontSize: {
      base: '3rem',
      md: '4rem',
    },
    fontWeight: '400',
    textAlign: 'center',
  },
  coloredBrand: {
    as: 'span',
    bgGradient: 'linear(to-l, #9107FE, #4CC672)',
    bgClip: 'text',
    fontSize: {
      base: '3rem',
      md: '4rem',
    },
    fontWeight: '400',
  },
  paragraph: {
    fontSize: {
      md: '1.125rem',
    },
    lineHeight: '2rem',
    w: '100%',
    maxW: {
      md: '90%',
    },
    textAlign: 'center',
  },
}

const copy = {
  emoji: '🔮',
  title: 'Explore the future with ',
  coloredBrand: 'Delphi',
  // cta: 'Esplora con Delphi',
  cta: 'Accedi',
  ctaDisabledSiteAccess: "Stiamo ristrutturando l'oracolo, torneremo presto",
  paragraph:
    'Delphi usa <strong>dell’AI generativa, trend di mercato e dati qualitativi</strong> di persone reali, per <strong>immaginare una moltitudine di scenari futuri</strong>, aiutandoti a prendere <strong>migliori decisioni strategiche</strong> per il tuo prodotto digitale.',
  keyboardBadge: {
    actionStartText: 'Premi',
    keyboardText: 'enter',
    actionEndText: 'per andare alla prossima domanda',
  },
}

const features = [
  {
    label: 'Analizza trend e dati reali di oggi',
    icon: <CheckCircleIcon color={'#9107FE'} boxSize={'26px'} />,
  },
  {
    label: 'Definisce utenti sintetici interattivi',
    icon: <CheckCircleIcon color={'#9107FE'} boxSize={'26px'} />,
  },
  {
    label: 'Elabora possibili scenari futuri',
    icon: <CheckCircleIcon color={'#9107FE'} boxSize={'26px'} />,
  },
]

const isSiteAccessEnabled = process.env.NEXT_PUBLIC_SITE_ACCESS_ENABLED === 'true'

export default function Home() {
  const ctaHref = APP_ROUTES.AUTH_SIGN_IN
  const ctaLabel = isSiteAccessEnabled ? copy.cta : copy.ctaDisabledSiteAccess
  const { deleteAllLocalStorageKeys } = useLocalStorageKeys()

  deleteAllLocalStorageKeys()

  return (
    <Content {...styles.mainWrapper}>
      <Center
        alignSelf={'center'}
        margin="0 auto"
        flexDirection="column"
        gap={'4rem'}
      >
        <ScaleFade initialScale={0.5} in={true}>
          <Flex {...styles.headWrapper}>
            <Text {...styles.emoji}>{copy.emoji}</Text>
            <Heading {...styles.heading}>
              {copy.title}
              <Text {...styles.coloredBrand}>{copy.coloredBrand}</Text>
            </Heading>
            <Text
              {...styles.paragraph}
              dangerouslySetInnerHTML={{ __html: copy.paragraph }}
            />
          </Flex>
        </ScaleFade>
        <Flex gap={'2.5rem'} flexDirection={{ base: 'column', md: 'row' }}>
          {features.map(({ icon, label }, index) => (
            <LabeledIcon key={index} icon={icon} label={label} />
          ))}
        </Flex>
        <Cta
          href={ctaHref}
          primary={Boolean(isSiteAccessEnabled && true)}
          secondary={Boolean(!isSiteAccessEnabled && true)}
          margin="0 auto"
          isDisabled={!isSiteAccessEnabled}
        >
          <Text {...styles.cta}>{ctaLabel}</Text>
        </Cta>
      </Center>
    </Content>
  )
}
