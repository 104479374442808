'use client'
import { LOCAL_STORAGE_KEY } from '@/lib/constants'
import * as React from 'react'

function useLocalStorageState(
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {}
) {
  const [state, setState] = React.useState(() => {
    const valueInLocalStorage =
      typeof window !== 'undefined' ? window.localStorage.getItem(key) : null

    if (valueInLocalStorage && valueInLocalStorage !== 'undefined') {
      return deserialize(valueInLocalStorage)
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue
  })

  const prevKeyRef = React.useRef(key)

  React.useEffect(() => {
    const prevKey = prevKeyRef.current
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey)
    }
    prevKeyRef.current = key
    window.localStorage.setItem(key, serialize(state))
  }, [key, state, serialize])

  return [state, setState]
}

const getLocalStorageItems = (keys = Object.keys(LOCAL_STORAGE_KEY)) => {
  const keysInLocalStorage = {}
  keys.forEach((key) => {
    const valueInLocalStorage =
      typeof window !== 'undefined'
        ? window.localStorage.getItem(LOCAL_STORAGE_KEY[key])
        : null

    if (valueInLocalStorage && valueInLocalStorage !== 'undefined') {
      keysInLocalStorage[key] = JSON.parse(valueInLocalStorage)
    }
  })
  return keysInLocalStorage
}

function useLocalStorageKeys() {
  const keys = Object.keys(LOCAL_STORAGE_KEY)
  const [localStorageKeys, setLocalStorageKeys] = React.useState(() => {
    return getLocalStorageItems(keys)
  })

  const deleteAllLocalStorageKeys = () => {
    if (typeof window === 'undefined') return
    if (!window.localStorage) return
    if (!keys || !keys.length) return

    keys.forEach((key) => {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY[key])
    })
  }

  React.useEffect(() => {
    keys.forEach((key) => {
      const valueInLocalStorage =
        typeof window !== 'undefined'
          ? window.localStorage.getItem(LOCAL_STORAGE_KEY[key])
          : null
      if (valueInLocalStorage && valueInLocalStorage !== 'undefined') {
        setLocalStorageKeys((prevLocalStorageKeys) => ({
          ...prevLocalStorageKeys,
          [key]: JSON.parse(valueInLocalStorage),
        }))
      }
    })
  }, [])

  return { localStorageKeys, deleteAllLocalStorageKeys }
}

export { useLocalStorageKeys, useLocalStorageState, getLocalStorageItems }
